import React from "react";
import PropTypes from "prop-types";

const IconLinkBroken = ({ color = "#5f5e70" }) => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <path
        d="M19.0848 14.8492L20.8596 13.0885C22.0263 11.9218 22.0334 10.0197 20.8596 8.84586C19.6858 7.67206 17.7908 7.67206 16.617 8.84586L14.8351 10.6278C14.4462 11.0167 13.8098 11.0167 13.4208 10.6278C13.0319 10.2389 13.0319 9.60246 13.4208 9.21355L15.2098 7.42457C17.1614 5.47296 20.3293 5.47296 22.2809 7.42457C24.2325 9.37619 24.2325 12.544 22.2809 14.4956L20.5131 16.2634C19.5656 17.1968 18.0878 15.8462 19.0848 14.8492Z"
        fill={color}
      />
      <path
        d="M10.9671 23.7447C9.68019 23.7447 8.40739 23.2497 7.42452 22.281C6.48406 21.3405 5.9608 20.0818 5.9608 18.7383C5.96788 17.4019 6.48406 16.1503 7.43159 15.2028L9.18521 13.4633C10.0762 12.5724 11.5399 13.9371 10.6065 14.8705L8.85287 16.6241C8.28012 17.1969 7.96899 17.9464 7.97606 18.7454C7.98313 19.5444 8.27304 20.3011 8.8458 20.8597C10.0125 22.0264 11.9146 22.0335 13.0884 20.8597L14.8138 19.1343C15.2027 18.7454 15.8391 18.7454 16.228 19.1343C16.6169 19.5232 16.6169 20.1596 16.228 20.5485L14.5027 22.2739C13.5268 23.2497 12.247 23.7376 10.9671 23.7447Z"
        fill={color}
      />
      <path
        d="M18.717 18.8232C19.1059 18.4343 19.7423 18.4343 20.1312 18.8232L22.2525 20.9445C22.6414 21.3334 22.6414 21.9698 22.2525 22.3587C21.8636 22.7476 21.2272 22.7476 20.8383 22.3587L18.717 20.2374C18.3281 19.8485 18.3281 19.2121 18.717 18.8232Z"
        fill={color}
      />
      <path
        d="M10.9105 11.0167C10.5215 11.4057 9.88515 11.4057 9.49624 11.0167L7.37492 8.89543C6.98602 8.50652 6.98602 7.87012 7.37492 7.48122C7.76383 7.09231 8.40023 7.09231 8.78914 7.48122L10.9105 9.60254C11.2994 9.99144 11.3064 10.6208 10.9105 11.0167Z"
        fill={color}
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect
          width="21.04"
          height="21.01"
          fill="white"
          transform="translate(14.8562) rotate(45)"
        />
      </clipPath>
    </defs>
  </svg>
);

IconLinkBroken.propTypes = {
  color: PropTypes.string
};

export default IconLinkBroken;
