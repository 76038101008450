import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { List } from "immutable";
import ImmutablePropTypes from "react-immutable-proptypes";

import ContentLayout from "Components/ContentLayout";
import ServicesDisplay from "Containers/ServicesDisplay";
import EnvironmentOverview from "Containers/EnvironmentOverview";

import ActivityList from "Containers/ActivityList";
import EnvironmentButtons from "Containers/EnvironmentButtons";
import ListGroup from "Components/ListGroup";
import Heading2 from "Components/styleguide/Heading2";
import {
  loadEnvironmentActivities,
  loadMoreEnvironmentActivities
} from "Reducers/activity";

const DashboardContainer = styled.div`
  width: 100%;
  > section,
  > aside > section {
    padding: 32px;
    box-sizing: border-box;
    max-width: 100%;
  }
  .environment-info,
  .apps-services {
    box-sizing: border-box;
    overflow: hidden;
  }
  .environment-activity-wrapper {
    padding: 32px 32px 16px;
    margin: 0;
    box-sizing: border-box;
    h2 {
      margin-bottom: 31px;
    }
  }
  @media (min-width: 768px) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;

    .environment-info {
      overflow: visible;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      section + section {
        margin-left: 32px;
      }
    }
    .apps-services {
      margin-bottom: 0;
    }
  }
  @media (min-width: 1024px) {
    flex-direction: row;
    .environment-info {
      display: inline-block;
      margin-right: 32px;
      flex-grow: 1;
      max-width: calc(84px * 4 + 32px * 3);
      section + section {
        margin-left: 0;
      }
    }
  }
  @media (min-width: 1920px) {
    .environment-info {
      min-width: 432px;
      max-width: calc(110px * 4 + 32px * 3);
    }
  }
`;

class Detail extends React.Component {
  constructor(props) {
    super(props);
    this.loadMoreEnvironmentActivities = this.loadMoreEnvironmentActivities.bind(
      this
    );
    this.loadActivitiesOfType = this.loadActivitiesOfType.bind(this);
  }
  componentDidMount() {
    if (this.props.environment.id) {
      this.props.loadEnvironmentActivities(
        this.props.params.projectId,
        decodeURIComponent(this.props.params.environmentId),
        "all_type"
      );
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.environment.id &&
      (this.props.params.environmentId !== nextProps.params.environmentId ||
        this.props.environment.id !== nextProps.environment.id)
    ) {
      this.props.loadEnvironmentActivities(
        nextProps.params.projectId,
        nextProps.params.environmentId,
        "all_type"
      );
    }
  }

  loadActivitiesOfType({ value }) {
    this.props.loadEnvironmentActivities(
      this.props.params.projectId,
      this.props.params.environmentId,
      value
    );
  }

  loadMoreEnvironmentActivities({ value }) {
    if (!this.props.isLoading) {
      this.props.loadMoreEnvironmentActivities(value);
    }
  }

  render() {
    const { intl } = this.props;
    if (!this.props.environment) {
      return false;
    }

    const environmentPublicUrl =
      this.props.environment._links &&
      this.props.environment._links["public-url"] &&
      this.props.environment._links["public-url"].href;
    return (
      <ContentLayout>
        <EnvironmentButtons
          className="buttons"
          router={this.props.router}
          project={this.props.project}
          {...this.props.params}
        />
        <DashboardContainer>
          <aside className="environment-info col-4">
            <ListGroup labelledby="environment-info-heading">
              {this.props.children || (
                <EnvironmentOverview
                  router={this.props.router}
                  project={this.props.project}
                  intl={intl}
                  {...this.props.params}
                />
              )}
            </ListGroup>
            <ListGroup
              className="apps-services"
              aria-labelledby="environment-services-heading"
            >
              <Heading2 id="environment-services-heading">
                {intl.formatMessage({ id: "apps_services" })}
              </Heading2>
              <ServicesDisplay
                height="250px"
                minHeight="250px"
                {...this.props.params}
              />
            </ListGroup>
          </aside>
          <ListGroup
            className="environment-activity-wrapper"
            aria-labelledby="activity"
          >
            <Heading2 id="activity">
              {intl.formatMessage({ id: "environment_activity" })}
            </Heading2>
            <ActivityList
              organizationId={this.props.params.organizationId}
              projectId={this.props.projectId}
              loadMore={this.loadMoreEnvironmentActivities}
              loadActivitiesOfType={this.loadActivitiesOfType}
              activities={this.props.activity}
              environmentPublicUrl={environmentPublicUrl}
              canEditProject={
                this.props.project && this.props.project.hasPermission("#edit")
              }
              isLoading={this.props.activityIsLoading}
              hasMore={this.props.hasMore}
              activityContext="environment"
            />
          </ListGroup>
        </DashboardContainer>
      </ContentLayout>
    );
  }
}

Detail.propTypes = {
  params: PropTypes.shape({
    projectId: PropTypes.string,
    environmentId: PropTypes.string,
    organizationId: PropTypes.string
  }),
  router: PropTypes.shape({
    push: PropTypes.func.isRequired
  }),
  intl: PropTypes.object,
  loadActivities: PropTypes.func,
  environment: PropTypes.object,
  project: PropTypes.object,
  loadMoreActivities: PropTypes.func,
  isLoading: PropTypes.bool,
  children: PropTypes.node,
  loadEnvironmentActivities: PropTypes.func,
  projectId: PropTypes.string,
  organizationId: PropTypes.string,
  activity: PropTypes.oneOfType([
    ImmutablePropTypes.map,
    ImmutablePropTypes.list
  ]),
  activityIsLoading: PropTypes.bool,
  hasMore: PropTypes.bool,
  loadMoreEnvironmentActivities: PropTypes.func
};

const mapStateToProps = (state, props) => ({
  environment:
    state.environment &&
    state.environment.getIn(
      [
        "data",
        props.params.organizationId,
        props.params.projectId,
        props.params.environmentId
      ],
      {}
    ),
  project:
    state.project &&
    state.project.getIn([
      "data",
      props.params.organizationId,
      props.params.projectId
    ]),
  activity: state.activity.getIn(
    [
      "byEnvironment",
      "data",
      props.params.organizationId,
      props.params.projectId,
      props.params.environmentId
    ],
    new List()
  ),
  hasMore: state.activity.getIn(["byProject", "hasMore"], false),
  activityIsLoading: state.activity.getIn(["byProject", "loading"])
});

const mapDispatchToProps = (dispatch, props) => ({
  loadEnvironmentActivities: (
    projectDescriptionId,
    environmentDescriptionId,
    type
  ) =>
    dispatch(
      loadEnvironmentActivities(
        projectDescriptionId,
        environmentDescriptionId,
        props.params.organizationId,
        type
      )
    ),
  loadMoreEnvironmentActivities: type =>
    dispatch(
      loadMoreEnvironmentActivities(
        props.params.projectId,
        props.params.environmentId,
        type
      )
    )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(Detail));
