import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import styled from "styled-components";
import { injectIntl } from "react-intl";
import moment from "moment";
import { Link } from "react-router";
import { LiveMessage } from "react-aria-live";
import ReactTooltip from "react-tooltip";

import Grid from "Components/Grid";
import ErrorBoundary from "Components/ErrorBoundary";
import Heading2 from "Components/styleguide/Heading2";
import IconLink from "Icons/IconLink";
import IconLinkBroken from "Icons/IconLinkBroken";
import ButtonLink from "Components/ButtonLink";
import InfoDialog from "Components/InfoDialog";
import { checkGoLive, httpStatusDisplay, dateAsUTC } from "Libs/utils";

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0;
  color: #38485e;
  .heading-wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
  }
  h2 {
    margin-bottom: 4px;
    &:first-letter {
      text-transform: none;
    }
  }
  .live {
    display: inline-block;
    line-height: 32px;
    background: ${props => props.theme.highlightBg};
    padding: 0 24px;
    border-radius: 4px;
    color: ${props => props.theme.links};
  }
  .label {
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #98a0ab;
    display: flex;
    align-items: center;
    &:first-letter {
      text-transform: uppercase;
    }
    .info-dialog {
      margin-bottom: -2px;
    }
  }
  .value,
  .status {
    font-size: 15px;
    line-height: 18px;
    margin-bottom: 8px;
    font-weight: 600;
  }
  a {
    color: ${props => props.theme.links};
    text-decoration: none;
    font-size: 14px;
    &.button {
      color: #fff;
      padding: 0 16px;
      font-size: 15px;
    }
  }
  .env-url {
    background: ${props => props.theme.highlightBg};
    margin: 0 -32px -32px;
    width: 100%;
    padding: 0 32px;
    border-radius: 0 0 4px 4px;
    height: 64px;
    display: flex;
    align-items: center;
    svg {
      min-width: 24px;
      path {
        fill: ${props => props.theme.links};
      }
    }
    > a {
      white-space: nowrap;
      display: inline-block;
      padding: 0 8px;
      max-width: calc(100% - 32px);
      overflow: hidden;
      text-overflow: ellipsis;
      text-decoration: none;
      position: relative;
      &:hover {
        &:after {
          content: "";
          width: calc(100% - 16px);
          position: absolute;
          height: 1px;
          background: ${props => props.theme.links};
          bottom: 0;
          left: 8px;
          display: inline-block;
        }
      }
      &:focus {
        outline: none;
        border: 1px solid ${props => props.theme.buttonHover};
        border-radius: 4px;
        box-shadow: 0 0 2px ${props => props.theme.buttonHover};
        text-decoration: none;
        margin: -1px;
      }
      &:active {
        border: none;
        box-shadow: none;
        text-decoration: underline;
        margin: 0;
      }
    }
    &.inactive {
      background: rgba(201, 208, 228, 0.5);
      color: #4a495e;
      font-size: 13px;
      font-weight: 600;
      svg {
        margin-right: 16px;
        path {
          fill: #4a495e;
        }
      }
    }
  }
`;

const GridWrapper = styled.div`
  width: 100%;
  padding-top: 32px;
  margin-top: 0 !important;
  .grid {
    color: #38485e;
    margin: 0 -32px 24px;
    display: flex;
    background: transparent;
    > div {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      &.environment-deployed,
      &.environment-backup {
        justify-content: flex-end;
        min-height: 72px;
      }
    }
    .time-ago,
    .time-date {
      font-weight: 600;
      font-size: 15px;
      line-height: 18px;
      margin-bottom: 8px;
    }
    .number {
      font-size: 24px;
    }
    .label {
      margin-bottom: 0;
    }
  }
`;

const ItemWrapper = styled.div`
  width: 100%;
  .label {
    margin-top: 0;
    margin-bottom: 16px;
  }
  .subtext {
    margin-top: 0;
  }
  .status {
    display: flex;
    align-items: center;
    font-weight: 600;
    .bullet {
      height: 6px;
      width: 6px;
      border-radius: 6px;
      background: #00c89c;
      display: inline-block;
      margin-right: 12px;
    }
    .text {
      display: inline-block;
      &:first-letter {
        text-transform: capitalize;
      }
    }
    &.status-inactive {
      .bullet {
        background: #98a0ab;
      }
    }
    &.status-dirty,
    &.status-disabled {
      .bullet {
        background: #ff8359;
      }
    }
  }
`;

// class EnvironmentOverview extends React.Component {
const EnvironmentOverview = ({
  intl,
  environment,
  project,
  projectId,
  environmentId,
  organizationId
}) => {
  const timeAgo = date => {
    let dateArray = moment(date)
      .fromNow()
      .split(/[\s,]+/);
    return {
      value: dateArray[0],
      label: dateArray.slice(1).join(" ")
    };
  };

  const formatDate = date => {
    return moment(date).format("d MMM Y, kk:mm");
  };

  if (!environment) {
    return false;
  }

  const routes = environment.getRouteUrls();
  const envUrl = routes.find(
    route => route.startsWith("https") && !route.includes("*")
  );
  const httpAccess = httpStatusDisplay(environment);
  const goLive = checkGoLive(project);
  moment.updateLocale("en", {
    relativeTime: {
      future: "in %s",
      past: "%s ago",
      s: "%d second",
      ss: "%d seconds",
      m: "%d minute",
      mm: "%d minutes",
      h: "%d hour",
      hh: "%d hours",
      d: "%d day",
      dd: "%d days",
      M: "%d month",
      MM: "%d months",
      y: "%d year",
      yy: "%d years"
    }
  });
  const lastActiveAt = timeAgo(environment.last_active_at);
  const lastBackupAt = environment.last_backup_at
    ? timeAgo(environment.last_backup_at)
    : null;

  return (
    <Layout>
      <div className="heading-wrapper">
        <Heading2 id="environmnet-info-heading">{environment.title}</Heading2>
        {environment.is_main &&
          goLive &&
          (goLive === "live" ? (
            <span className="live">Live</span>
          ) : goLive === "development" ? (
            <ButtonLink
              to={project && project.data && project.data.plan_uri}
              className="go-live"
              text="Go live"
              external={true}
              blank={false}
            />
          ) : goLive === "no-permission" ? (
            ""
          ) : (
            <ButtonLink
              to={`/${organizationId}/${projectId}/settings/domains`}
              className="go-live"
              text="Go live"
            />
          ))}
      </div>
      <div className="label">{!environment.is_main && "Development"}</div>
      <ErrorBoundary>
        <LiveMessage
          message={`${environment.title} overview`}
          aria-live="polite"
        />
        <GridWrapper>
          <Grid>
            <ItemWrapper className="environment-status">
              <div className={`status status-${environment.status}`}>
                <span className="bullet" />
                <span className="text">
                  {environment.status === "active" && "Active"}
                  {environment.status === "dirty" && "Building"}
                  {environment.status !== "active" &&
                    environment.status !== "dirty" &&
                    environment.status}
                </span>
              </div>
              <div className="label">
                Status{" "}
                <InfoDialog
                  title="Status"
                  text="To edit the status of this environment, review your general settings."
                  to={`/${organizationId}/${projectId}/${encodeURIComponent(
                    environmentId
                  )}/settings`}
                  linkText="View settings"
                  toLocal={true}
                  to2="https://docs.platform.sh/GLOSSARY.html#inactive-environment"
                  linkText2="Learn more"
                />
              </div>
            </ItemWrapper>
            <ItemWrapper className="environment-access">
              <div
                className={`status status-${
                  httpAccess ? "enabled" : "disabled"
                }`}
              >
                {httpAccess ? "Enabled" : "Disabled"}
              </div>
              <div className="label">HTTP access</div>
            </ItemWrapper>
          </Grid>
          <Grid>
            <ItemWrapper className="environment-deployed">
              <div
                className="time-ago"
                data-tip
                data-for="last-deployed-tooltip"
              >
                <span className="number">{lastActiveAt.value}</span>{" "}
                {lastActiveAt.label}
              </div>
              <ReactTooltip
                id="last-deployed-tooltip"
                place="left"
                type="dark"
                effect="solid"
              >
                UTC: {dateAsUTC(environment.last_active_at)}
              </ReactTooltip>
              <div className="time-date">
                {formatDate(environment.last_active_at)}
              </div>
              <div className="label">
                {intl.formatMessage({ id: "last_activity" })}
              </div>
            </ItemWrapper>

            <ItemWrapper className="environment-backup">
              <div className="time-ago">
                {lastBackupAt && (
                  <React.Fragment>
                    <span className="number">{lastBackupAt.value + " "}</span>
                    {lastBackupAt.label}
                  </React.Fragment>
                )}
              </div>
              {lastBackupAt ? (
                <div className="time-date">
                  {formatDate(environment.last_backup_at)}
                </div>
              ) : (
                <div className="time-date no-backup">
                  {intl.formatMessage({ id: "no_backup" })}
                </div>
              )}
              <div className="label">
                {intl.formatMessage({ id: "last_backup" })}
              </div>
            </ItemWrapper>
          </Grid>
          <Grid>
            <ItemWrapper className="environment-machine">
              <div className="machine-name">
                <div className="value">{environment.machine_name}</div>
                <div className="label">
                  {intl.formatMessage({ id: "machine_name" })}
                </div>
              </div>
            </ItemWrapper>
          </Grid>
        </GridWrapper>
        {environment.status === "inactive" ? (
          <div className="env-url inactive">
            <IconLinkBroken />
            Environment inactive
            <InfoDialog
              title="URL unavailable"
              text="This environment does not have a URL as the status is inactive. You can update your status in general settings."
              to={`/${organizationId}/${projectId}/${encodeURIComponent(
                environmentId
              )}/settings`}
              linkText="View settings"
              toLocal={true}
              to2="https://docs.platform.sh/GLOSSARY.html#inactive-environment"
              linkText2="Learn more"
            />
          </div>
        ) : (
          <div className="env-url">
            <IconLink />
            <Link
              to={envUrl}
              rel="noopener noreferrer"
              target="_blank"
              data-tip
              data-for="environment-url-full"
            >
              {envUrl}
            </Link>
            <ReactTooltip
              id="environment-url-full"
              place="bottom"
              effect="solid"
            >
              {envUrl}
            </ReactTooltip>
          </div>
        )}
      </ErrorBoundary>
    </Layout>
  );
};

const mapStateToProps = (state, props) => {
  const environment = state.environment.getIn([
    "data",
    props.organizationId,
    props.projectId,
    props.environmentId
  ]);
  return {
    environment,
    isLoading: state.environment.get("loading")
  };
};

EnvironmentOverview.propTypes = {
  projectId: PropTypes.string,
  environmentId: PropTypes.string.isRequired,
  organizationId: PropTypes.string,
  intl: PropTypes.object.isRequired,
  environment: PropTypes.object,
  project: PropTypes.object
};

export default connect(mapStateToProps)(injectIntl(EnvironmentOverview));
