import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import styled from "styled-components";
import { injectIntl } from "react-intl";
import { Link } from "react-router";

import { MACHINE_NAME } from "Constants/constants";
import { getEnvironmentGitCommand } from "../libs/utils";
import InfoButtonsWrapper from "Components/InfoButtonsWrapper";
import ActionDropdown from "Components/ActionDropdown";
import CopyableArea from "Components/CopyableArea";
import EnvironmentRoutes from "Containers/EnvironmentRoutes";

const Layout = styled.div`
  padding-bottom: 16px;
  margin-left: 156px;
  min-width: 215px;
  > section {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    box-sizing: border-box;

    @media (min-width: 960px) {
      flex-direction: row;
      > div {
        max-width: 50%;
      }
    }
  }
  #environment-urls {
    section {
      padding: 0;
      a:hover {
        span.path {
          position: relative;
          &:after {
            content: "";
            width: 100%;
            position: absolute;
            height: 1px;
            background: ${props => props.theme.links};
            bottom: 7px;
            left: 0;
            display: inline-block;
          }
        }
      }
    }
  }
  .rcs-custom-scroll {
    .rcs-inner-container {
      padding: 16px;
      box-sizing: border-box;
    }
    .rcs-inner-handle {
      background: #4a495e;
      width: 4px;
      border-radius: 4px;
      opacity: 1;
    }
  }
  .no-scroll {
    padding: 16px;
  }
  @media (min-width: 768px) {
    position: absolute;
    top: -48px;
    right: 0;
    padding-bottom: 0;
  }
`;

const DownloadWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
  font-size: 15px;
  a {
    color: ${props => props.theme.links};
    display: flex;
    align-items: center;
    text-decoration: none;
    svg {
      margin-right: 8px;
    }
    .download-icon {
      fill: ${props => props.theme.links};
    }
  }
`;

class EnvironmentButtons extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
    this.securePath = this.securePath.bind(this);
    this.isInactive = this.isInactive.bind(this);
  }

  securePath(path) {
    return path.startsWith("https");
  }

  isInactive(status) {
    return status === "inactive";
  }

  render() {
    const {
      environmentId,
      organizationId,
      projectId,
      environment,
      project,
      intl
    } = this.props;

    if (!environment) {
      return false;
    }

    const sshUrls = environment.getSshUrls() || {};
    const sshCommands = Object.keys(sshUrls).map(key =>
      sshUrls[key].replace("ssh://", "ssh ")
    );
    const cliCommand = getEnvironmentGitCommand(
      project,
      environment,
      MACHINE_NAME,
      "git"
    );
    const platformCliCommand = getEnvironmentGitCommand(
      project,
      environment,
      MACHINE_NAME,
      "cli"
    );

    return (
      <Layout aria-label="Environment buttons">
        <InfoButtonsWrapper className="info-buttons">
          <ActionDropdown
            id="environment-urls"
            label={intl.formatMessage({ id: "urls" })}
            className={environment.status === "inactive" ? "disabled" : ""}
            ariaLabel={intl.formatMessage({ id: "urls" })}
            status={environment.status}
          >
            <EnvironmentRoutes
              projectId={projectId}
              organizationId={organizationId}
              environmentId={environmentId}
            />
          </ActionDropdown>
          <ActionDropdown id="environment-git" label="GIT" ariaLabel="Git">
            <CopyableArea
              id="environment-git-cmd"
              content={cliCommand}
              singleLine={true}
            >
              {cliCommand}
            </CopyableArea>
          </ActionDropdown>
          <ActionDropdown id="environment-cli" label="CLI" ariaLabel="CLI">
            <CopyableArea
              id="environment-cli-cmd"
              content={platformCliCommand}
              singleLine={true}
            >
              {platformCliCommand}
            </CopyableArea>
            <DownloadWrapper>
              <Link
                to="https://github.com/platformsh/platformsh-cli"
                tabIndex="0"
                aria-label="Insall CLI"
                target="_blank"
                rel="noopener noreferrer"
              >
                Install CLI
              </Link>
            </DownloadWrapper>
          </ActionDropdown>
          <ActionDropdown
            id="environment-ssh"
            label="SSH"
            className={environment.status === "inactive" ? "disabled" : ""}
            ariaLabel="ssh"
            status={environment.status}
          >
            {sshCommands.map((ssh, index) => (
              <CopyableArea
                id={`environment-ssh-cmd-${index}`}
                key={ssh}
                content={ssh}
                singleLine={true}
              >
                {ssh}
              </CopyableArea>
            ))}
          </ActionDropdown>
        </InfoButtonsWrapper>
      </Layout>
    );
  }
}

const mapStateToProps = (state, props) => {
  const environment = state.environment.getIn([
    "data",
    props.organizationId,
    props.projectId,
    props.environmentId
  ]);

  return {
    environment,
    isLoading: state.environment.get("loading")
  };
};

EnvironmentButtons.propTypes = {
  projectId: PropTypes.string,
  environmentId: PropTypes.string,
  organizationId: PropTypes.string,
  router: PropTypes.shape({
    push: PropTypes.func.isRequired
  }),
  environment: PropTypes.object,
  project: PropTypes.object,
  intl: PropTypes.object
};

export default connect(mapStateToProps)(injectIntl(EnvironmentButtons));
