import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: ${props => props.gridTemplateColumns};
  grid-template-rows: ${props => props.gridTemplateRows};
  justify-content: stretch;
  justify-items: stretch;
  align-items: center;
  grid-row-gap: 1px;
  grid-column-gap: 1px;
  background: #eee;
  > div {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin: 0;
    padding: 0 32px;
    box-sizing: border-box;
    justify-content: space-between;
    align-items: stretch;
  }
`;

class Grid extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { children } = this.props;
    return (
      <Wrapper className="grid" {...this.props}>
        {children}
      </Wrapper>
    );
  }
}

Grid.propTypes = {
  gridTemplateColumns: PropTypes.string,
  gridTemplateRows: PropTypes.string,
  children: PropTypes.node
};

export default Grid;
